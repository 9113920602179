import { createSelector, createSlice, current } from '@reduxjs/toolkit'

export const actualSlice = createSlice({
    name: "actual",
    initialState: {
        totals: {},
        lines: {},
        bills: {},

        billsPopupOpen: false,
        linesPopupOpen: false,
        linePopupOpen: false,

        tab: 0,
        tabs: {
            0:{toAllocateBills: [], toAllocateLines: [], allocations: [], searchedLineIdsSet: null, searchedBillIdsSet: null},
            1:{toAllocateBills: [], toAllocateLines: [], allocations: [], searchedLineIdsSet: null, searchedBillIdsSet: null}
        },

        deallocatingBill: null,
        refutingBill: null,
        confirmingRefutedBill: null
    },

    reducers: {
        updateTotals: (state, action) => {
            const {
                quoted,
                client_budget,
                running,
                actual,
                actual_variance,
                running_variance,

                billed,
            
                hard_cost_budget,
                hard_cost_running,
                hard_cost_actual,
                hard_cost_actual_variance,
                hard_cost_running_variance,
            
                internal_budget,
                internal_running,
                internal_actual,
                internal_actual_variance,
                internal_running_variance,
            
                gross_profit,
                gross_profit_running,
                gross_profit_actual,
                gross_profit_actual_variance,
                gross_profit_running_variance,

                net_profit,

                currency
            } = action.payload

            state.totals = {
                quoted,
                client_budget,
                running,
                actual,
                actual_variance,
                running_variance,

                billed,
            
                hard_cost_budget,
                hard_cost_running,
                hard_cost_actual,
                hard_cost_actual_variance,
                hard_cost_running_variance,
            
                internal_budget,
                internal_running,
                internal_actual,
                internal_actual_variance,
                internal_running_variance,
            
                gross_profit,
                gross_profit_running,
                gross_profit_actual,
                gross_profit_actual_variance,
                gross_profit_running_variance,

                net_profit,

                currency
            }
        },
        updateLines: (state, action) => {
            const lines = action.payload
            state.lines = lines.reduce((o, line) => ({...o, [line.id]: {...line}}), {})
        },
        updateLine: (state, action) => {
            const line = action.payload
            state.lines[line.id] = line
        },
        updateBills: (state, action) => {
            const bills = action.payload
            state.bills = bills.reduce((o, bill) => ({...o, [bill.id]: {...bill}}), {})
        },
        updateBill: (state, action) => {
            const bill = action.payload
            state.bills[bill.id] = bill
        },
        openBillsPopup: (state) => {
            state.billsPopupOpen = true
        },
        closeBillsPopup: (state) => {
            state.billsPopupOpen = false
        },
        openLinesPopup: (state) => {
            state.linesPopupOpen = true
        },
        closeLinesPopup: (state) => {
            state.linesPopupOpen = false
        },
        openLinePopup: (state) => {
            state.linePopupOpen = true
        },
        closeLinePopup: (state) => {
            state.linePopupOpen = false
        },
        switchTab: (state, action) => {
            state.tab = action.payload
        },

        checkBill: (state, action) => {
            const allocationContext = state.tabs[state.tab]
            const bill = state.bills[action.payload]
            const canMultiSelect = allocationContext.toAllocateLines.length <= 1
            if (canMultiSelect) {
                allocationContext.toAllocateBills.push(bill)
            } else {
                allocationContext.toAllocateBills = [bill]
            }
        },
        checkLine: (state, action) => {
            const allocationContext = state.tabs[state.tab]
            const line = state.lines[action.payload]
            const canMultiSelect = allocationContext.toAllocateBills.length <= 1
            if (canMultiSelect) {
                allocationContext.toAllocateLines.push(line)
            } else {
                allocationContext.toAllocateLines = [line]
            }
        },
        uncheckBill: (state, action) => {
            const allocationContext = state.tabs[state.tab]
            const billId = action.payload
            allocationContext.toAllocateBills = allocationContext.toAllocateBills.filter(bill=>bill.id!=billId)
        },
        uncheckLine: (state, action) => {
            const allocationContext = state.tabs[state.tab]
            const lineId = action.payload
            allocationContext.toAllocateLines = allocationContext.toAllocateLines.filter(line=>line.id!=lineId)
        },
        startAllocation: (state) => {
            const allocationContext = state.tabs[state.tab]
            const toAllocateLines = allocationContext.toAllocateLines
            const toAllocateBills = allocationContext.toAllocateBills
            const allocations = allocationContext.allocations

            const multiLineMode = toAllocateLines.length > 1 && toAllocateBills.length == 1
            const oneLineMode = (toAllocateLines.length == 1 && toAllocateBills.length >= 1)
            
            if (multiLineMode) {
                toAllocateLines.forEach(line => {
                    allocations.push({
                        actual_line_id: line.id,
                        accounting_bill_id: toAllocateBills[0].id,
                        actual: 0
                    })
                })
            } else if (oneLineMode) {
                toAllocateBills.forEach(bill => {
                    allocations.push({
                        actual_line_id: toAllocateLines[0].id,
                        accounting_bill_id: bill.id, 
                        actual: bill.actual
                    })
                })
            } else {
                throw "Invalid line and bill combinations"
            }
        },
        stopAllocation: (state) => {
            const allocationContext = state.tabs[state.tab]
            allocationContext.allocations = []
        },
        editAllocation: (state, action) => {
            const { model_id, model_type_id_key, data } = action.payload
            const allocationContext = state.tabs[state.tab]
            allocationContext.allocations = allocationContext.allocations.map(allocation => 
                allocation[model_type_id_key] == model_id ? {...allocation, ...data} : allocation
            )
        },
        resetAllocations: (state) => {
            const allocationContext = state.tabs[state.tab]
            allocationContext.allocations = []
        },
        resetAll: (state) => {
            const allocationContext = state.tabs[state.tab]
            allocationContext.toAllocateBills = []
            allocationContext.toAllocateLines = []
            allocationContext.allocations = []
        },
        distributeRemaining: (state) => {
            const allocationContext = state.tabs[state.tab]
            let workingBill = null
            if (allocationContext.toAllocateBills.length == 1 && allocationContext.toAllocateLines.length > 1){
                workingBill = allocationContext.toAllocateBills[0]
            }

            const allocationsToDistribute = allocationContext.allocations.filter((line)=>line.actual == 0).length
            const allocateTotal = allocationContext.allocations.reduce((total, allocation)=>total + allocation.actual, 0)
            const portion = (workingBill.actual - allocateTotal) / allocationsToDistribute
            allocationContext.allocations = allocationContext.allocations.map(allocation => allocation.actual == 0 ? {...allocation, actual: portion} : allocation)
        },
        searchLines: (state, action) => {
            const searchString = action.payload
            const allocationContext = state.tabs[state.tab]
            if (!searchString) {
                allocationContext.searchedLineIdsSet = null
            }
            const searchedLines = Object.values(state.lines).filter(line => line.name.toLowerCase().includes(searchString))
            allocationContext.searchedLineIdsSet = Object.fromEntries(searchedLines.map(line=>[line.id, true]))
        },
        searchBills: (state, action) => {
            const searchString = action.payload
            const allocationContext = state.tabs[state.tab]
            if (!searchString) {
                allocationContext.searchedBillIdsSet = null
            }
            const searchedBills = Object.values(state.bills).filter(bill => bill.vendor_name.toLowerCase().includes(searchString))
            allocationContext.searchedBillIdsSet = Object.fromEntries(searchedBills.map(bill=>[bill.id, true]))
        },
        setDeallocatingBill: (state, action) => {
            state.deallocatingBill = action.payload
        },
        cancelDeallocatingBill: (state, action) => {
            state.deallocatingBill = null
        },
        setRefutingBill: (state, action) => {
            state.refutingBill = action.payload
        },
        cancelRefutingBill: (state, action) => {
            state.refutingBill = null
        },
        setConfirmingRefutedBill: (state, action) => {
            state.confirmingRefutedBill = action.payload
        },
        cancelConfirmingRefutedBill: (state, action) => {
            state.confirmingRefutedBill = null
        },
    }
})

export const {
    updateTotals, updateLines, updateLine, updateBills, updateBill,
    checkBill, checkLine, uncheckBill, uncheckLine,
    startAllocation, stopAllocation, editAllocation,
    openBillsPopup, openLinesPopup, closeBillsPopup, closeLinesPopup, openLinePopup, closeLinePopup,
    resetAll, resetAllocations, switchTab,
    distributeRemaining,
    searchBills, searchLines,
    cancelDeallocatingBill, setDeallocatingBill,
    cancelRefutingBill, setRefutingBill,
    cancelConfirmingRefutedBill, setConfirmingRefutedBill
} = actualSlice.actions

export const selectTotals = (state) => state.actual.totals
export const selectProjectCurrency = (state) => state.actual.totals.currency
export const selectLineIds = (state) => state.actual.lineIds
export const selectLines = (state) => state.actual.lines
export const selectLine = (state, lineId) => state.actual.lines[lineId]
export const selectBill = (state, billId) => state.actual.bills[billId]
export const selectToAllocateLines = (state) => state.actual.tabs[state.actual.tab].toAllocateLines
export const selectToAllocateBills = (state) => state.actual.tabs[state.actual.tab].toAllocateBills
export const selectCanMultiCheckLines = (state) => state.actual.tabs[state.actual.tab].toAllocateBills.length <= 1
export const selectCanMultiCheckBills = (state) => state.actual.tabs[state.actual.tab].toAllocateLines.length <= 1
export const selectAllocations = (state) => state.actual.tabs[state.actual.tab].allocations
export const selectLineIsSearched = (state, lineId) => state.actual.tabs[state.actual.tab].searchedLineIdsSet ? lineId in state.actual.tabs[state.actual.tab].searchedLineIdsSet : true
export const selectSearchedLineIdsSet = (state) => state.actual.tabs[state.actual.tab].searchedLineIdsSet
export const selectBillIsSearched = (state, billId) => state.actual.tabs[state.actual.tab].searchedBillIdsSet ? billId in state.actual.tabs[state.actual.tab].searchedBillIdsSet : true
export const selectLinesPopupOpen = (state) => state.actual.linesPopupOpen
export const selectBillsPopupOpen = (state) => state.actual.billsPopupOpen
export const selectLinePopupOpen = (state) => state.actual.linePopupOpen
export const selectTab = (state) => state.actual.tab
export const selectDeallocatingBill = (state) => state.actual.deallocatingBill
export const selectRefutingBill = (state) => state.actual.refutingBill
export const selectConfirmingRefutedBill = (state) => state.actual.confirmingRefutedBill
export const selectChecksAreValid = (state) => state.actual.tabs[state.actual.tab].toAllocateLines.length > 0 && state.actual.tabs[state.actual.tab].toAllocateBills.length > 0

export default actualSlice.reducer