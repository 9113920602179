import { CustomBox } from "@/common/StyledComponents";
import { Button, Modal, useTheme } from "@mui/material";
import AccountingBills from "./AccountingBills";
import { useDispatch } from "react-redux";
import { closeBillsPopup, selectBillsPopupOpen, selectChecksAreValid, startAllocation } from "./actualSlice";
import { useSelector } from "react-redux";

export default function AccountingBillsPopup({bills}){
    const dispatch = useDispatch()
    const theme = useTheme()
    const open = useSelector(selectBillsPopupOpen)
    const checksAreValid = useSelector(selectChecksAreValid)
    const handleAllocate = () => {
        dispatch(startAllocation())
    }
    const handleClose = () => {
        dispatch(closeBillsPopup())
    }
    return (
    <Modal open={open} onClose={handleClose}>
        <CustomBox sx={{ 
            padding: 5,
            background: theme.palette.background.default, 
            overflow: "scroll",
            width: "80%"
        }}>
            <AccountingBills bills={bills} selectable={true}/>
            <div style={{width: "100%", textAlign: "right"}}>
                <Button disabled={!checksAreValid} onClick={handleAllocate} variant="contained">Assign to Bills</Button>
            </div>
        </CustomBox>
    </Modal>
    )
}