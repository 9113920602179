import { FlexCell, FlexRow } from "@/common/StyledComponents";
import { formattedCurrency } from "@/utils";
import { Divider, Grid, Paper, useTheme } from "@mui/material";
import { selectProjectCurrency, selectTotals } from "./actualSlice";
import { useSelector } from "react-redux";

const TotalRow = ({rowLabelComponent, estimated, running, runningVariance, actual, color, fontWeight, actualVariance}) => {
    return (
        <>
            <FlexRow style={{height: 40}}>
                <FlexCell minWidth={115}>{rowLabelComponent}</FlexCell>
                <TotalFlexCell total={estimated} fontWeight={fontWeight} color={color}/>
                <TotalFlexCell total={actual} fontWeight={fontWeight} color={color} />
                <div style={{paddingRight: 40}}>
                    <TotalFlexCell total={actualVariance} fontWeight={fontWeight} color={color} />
                </div>
                <Divider orientation="vertical"/>
                <div style={{paddingLeft: 40}}>
                    <TotalFlexCell total={running} fontWeight={fontWeight} color={color}/>
                </div>
                <TotalFlexCell total={runningVariance} fontWeight={fontWeight} color={color}/>
            </FlexRow>
            <Divider sx={{borderColor: "#aaaaaa44"}}/>
        </>
    )
}

const TotalFlexCell = ({total, color, fontWeight}) => {
    const theme = useTheme()
    const isNumber = !isNaN(total)
    const projectCurrency = useSelector(selectProjectCurrency)
    color = total == "N/A" ? "#aaaaaaaa" : color
    return (
        <>
            <FlexCell align="right" style={{maxWidth: 120, minWidth: 140}}>
                {<span style={{fontWeight: fontWeight ? fontWeight : 400, color: color ? color : (total < 0 ? "red" : theme.palette.text.primary)}}>
                    {isNumber ? formattedCurrency(total, projectCurrency, false, 2) : total}
                </span>}
            </FlexCell>
        </>
    )
}

const ProfitNumber = ({amount, margin}) => {
    const projectCurrency = useSelector(selectProjectCurrency)
    const color = (!isNaN(amount) && amount < 0) ? "red" : "gray"
    return (
        <Grid container justifyContent={"right"} alignItems={"center"}>
            <Grid item sx={{color: color, paddingRight: 0.6, fontSize: 10}}>{`(${(margin * 100).toFixed(1)}%)`}</Grid>
            <Grid item sx={{color: color}}>{formattedCurrency(amount, projectCurrency, false, 2)}</Grid>
        </Grid>
    )
}

export default function ActualTotals(){
    const totals = useSelector(selectTotals)

    /*

    NOTE: the calculations made here are not to replace backend getters, but for exceptionally agile development / testing purposes

    */
   
    const gross_profit_margin = totals.gross_profit/totals.quoted
    const net_profit_margin = totals.net_profit/totals.quoted

    const actual_gross_profit = totals.billed - totals.hard_cost_actual
    const actual_gross_profit_margin = totals.billed ? actual_gross_profit / totals.billed : 0

    const actual_net_profit = actual_gross_profit - totals.internal_actual
    const actual_net_profit_margin = totals.billed ? actual_net_profit / totals.billed : 0

    return (
        <>
            <Paper sx={{padding: "10px 35px 25px 35px", boxShadow: "none", overflow: "auto"}}>
                <TotalRow
                    color={"gray"}
                    rowLabelComponent={<h4>Budget</h4>} estimated={"Estimated"}
                    running={"Running"} runningVariance={"Running Remaining"}
                    actual={"Actual"} actualVariance={"Actual Remaining"}
                />
                <TotalRow
                    fontWeight={600}
                    rowLabelComponent={<span style={{fontWeight: 600}}>- Total Costs</span>}
                    estimated={totals.client_budget}
                    running={totals.running} runningVariance={totals.running_variance}
                    actual={totals.actual} actualVariance={totals.actual_variance}
                />
                <TotalRow
                    rowLabelComponent={<span style={{marginLeft: 30}}>- Hard Costs</span>}
                    estimated={totals.hard_cost_budget}
                    running={totals.hard_cost_running} runningVariance={totals.hard_cost_running_variance}
                    actual={totals.hard_cost_actual} actualVariance={totals.hard_cost_actual_variance}
                />
                <TotalRow
                    rowLabelComponent={<span style={{marginLeft: 30}}>- Internal Costs</span>}
                    estimated={totals.internal_budget}
                    running={totals.internal_running} runningVariance={totals.internal_running_variance}
                    actual={totals.internal_actual} actualVariance={totals.internal_actual_variance}
                />
            </Paper>
            <br/>
            <Paper sx={{padding: "10px 35px 25px 35px", boxShadow: "none", overflow: "auto"}}>
                <TotalRow
                    color={"gray"}
                    rowLabelComponent={<h4>Profitability</h4>} estimated={"Estimated"}
                    running={<span style={{color: "#aaaaaaaa"}}>Running</span>} runningVariance={<span style={{color: "#aaaaaaaa"}}>Running Remaining</span>}
                    actual={"Actual"} actualVariance={"Actual Remaining"}
                />
                <TotalRow
                    fontWeight={600}
                    rowLabelComponent={<span style={{fontWeight: 600}}>- Total Quoted</span>}
                    estimated={totals.quoted}
                    running={"N/A"} runningVariance={"N/A"}
                    actual={totals.billed} actualVariance={totals.quoted - totals.billed}
                />
                <TotalRow
                    rowLabelComponent={<span style={{marginLeft: 30}}>- Gross Profit</span>}
                    estimated={<ProfitNumber amount={totals.gross_profit} margin={gross_profit_margin} />}
                    running={"N/A"} runningVariance={"N/A"}
                    actual={<ProfitNumber amount={actual_gross_profit} margin={actual_gross_profit_margin} />} actualVariance={"N/A"}
                />
                <TotalRow
                    rowLabelComponent={<span style={{marginLeft: 30}}>- Net Profit</span>}
                    estimated={<ProfitNumber amount={totals.net_profit} margin={net_profit_margin} />}
                    running={"N/A"} runningVariance={"N/A"}
                    actual={<ProfitNumber amount={actual_net_profit} margin={actual_net_profit_margin} />} actualVariance={"N/A"}
                />
            </Paper>
        </>
    )
}