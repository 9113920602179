import { FlexCell, FlexRow } from "@/common/StyledComponents"
import { Checkbox, Collapse, IconButton, Paper, Radio, TextField, useTheme } from "@mui/material"
import { useEffect, useState } from "react"
import { Grid } from "@mui/material"
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import { AccountingBillHeaderLine, AccountingBill } from "./AccountingBill";
import { useDispatch, useSelector } from "react-redux";
import { checkLine, editAllocation, selectCanMultiCheckLines, selectLine, selectLineIsSearched, selectProjectCurrency, selectToAllocateLines, uncheckLine, updateLine, updateTotals } from "./actualSlice";
import { formattedCurrency, getCurrencySymbol } from "@/utils";
import { request } from "@/Api";
import { NumericFormat } from "react-number-format/dist/react-number-format.cjs";

export function ActualLine({lineId, isLeaf, allocations, billCurrency, selectable}){
    const theme = useTheme()
    const dispatch = useDispatch()
    const line = useSelector(state => selectLine(state, lineId))
    const searched = useSelector(state => selectLineIsSearched(state, lineId))
    const projectCurrency = useSelector(selectProjectCurrency)
    const [ expanded, setExpanded ] = useState(false) 

    const handlePortionOfBillInput = (event) => {
        const amount = event.target.value ? parseFloat(event.target.value) : 0
        dispatch(editAllocation({
            "model_type_id_key": "actual_line_id",
            "model_id": lineId,
            "data": {"actual": amount}
        }))
    }

    const handleNumberInput = (field) => (values) => {
        dispatch(updateLine({...line, [field]: values.value}))
    }

    const handleInput = (field) => (event) => {
        const value = event.target.value
        dispatch(updateLine({...line, [field]: value}))
    }

    const handleBlur = (_) => {
        request.put(`actual-lines/${lineId}`, line)
        .then(response=>{
            dispatch(updateLine({...line, ...response.data}))

            request.get(`/projects/${line.project_id}/actuals`)
            .then(response=>{
                const projectData = response.data
                dispatch(updateTotals(projectData))
            })
        })
    }

    if (!line || !searched){
        return
    }

    let hasBills = line.actual_line_accounting_bills.length > 0

    return (
        <>
            <FlexRow style={{paddingLeft: 25, paddingBottom: 10, gap: "20px"}}>
                {selectable &&<FlexCell style={{minWidth: 30, maxWidth: 30}}>
                    <ActualLineToggle lineId={lineId}/>
                </FlexCell>}
                <FlexCell minWidth={80}>
                    <Grid container wrap="nowrap" alignItems={"center"}>
                        <Grid item><span>{line.name}</span></Grid>
                        {!isLeaf && 
                        <Grid item>
                            <IconButton onClick={()=>{setExpanded(!expanded)}} disabled={!hasBills}>
                                <ArticleOutlinedIcon />
                            </IconButton>
                        </Grid>}
                    </Grid>
                </FlexCell>
                {!allocations && <FlexCell minWidth={250}>
                    <TextField
                        sx={{marginRight: 10}}
                        size="small"
                        inputProps={{style:{
                            padding: "12px 20px 12px 20px"
                        }}}
                        InputProps={{style:{
                            borderRadius: 10,
                            fontSize: 12
                        }}}
                        fullWidth
                        value={line.notes || ""}
                        onInput={handleInput("notes")}
                        onBlur={handleBlur}
                    />
                </FlexCell>}
                <FlexCell align="right"><span>{formattedCurrency(line.budget, line.vendor_currency || projectCurrency, false, 2)}</span></FlexCell>
                {!allocations && 
                <FlexCell style={{marginLeft: 20, minWidth: 120}}>
                    <NumericFormat
                        customInput={TextField}
                        value={line.running}
                        sx={{width:"100%", fontSize: 1}}
                        inputProps={{
                            style: {textAlign: "right", fontSize: 12, padding: "12px 15px 12px 15px",},
                        }}
                        InputProps={{
                            style:{
                                borderRadius: 10,
                                fontSize: 12,
                            },
                        }}
                        prefix={getCurrencySymbol(line.vendor_currency || projectCurrency)}
                        thousandsGroupStyle="thousand"
                        thousandSeparator=","
                        decimalScale={2}
                        fixedDecimalScale
                        onValueChange={handleNumberInput("running")}
                        onBlur={handleBlur}
                        onFocus={(e)=>{e.target.select()}}
                        size="small"
                    />
                </FlexCell>}
                {!allocations && 
                <FlexCell item align="right">
                <span 
                style={{
                    color: 
                    line.running_variance < 0 ? "red" :
                    theme.palette.text.primary}}
                >{formattedCurrency(line.running_variance, line.vendor_currency || projectCurrency, false, 2)}</span></FlexCell>}
                <FlexCell item align="right"><span>{formattedCurrency(line.actual, line.vendor_currency || projectCurrency, false, 2)}</span></FlexCell>
                <FlexCell item align="right"><span>{formattedCurrency(line.actual_project, projectCurrency, false, 2)}</span></FlexCell>
                {allocations && 
                <FlexCell>
                    <TextField 
                        type="number"
                        variant="standard"
                        size="small"
                        inputProps={{style:{
                            textAlign: "right",
                        }}}
                        InputProps={{
                            style:{
                                borderRadius: 10,
                                fontSize: 12,
                            },
                            startAdornment: <span>{getCurrencySymbol(billCurrency)}</span> // TODO: should be bill currency
                        }}
                        fullWidth
                        value={allocations.find(allocation=>allocation.actual_line_id == lineId).actual}
                        onInput={handlePortionOfBillInput}
                        onFocus={(e)=>{e.target.select()}}
                    />
                </FlexCell>}
            </FlexRow>
            {!isLeaf && 
            <Collapse in={expanded} sx={{width: "100%"}}>
                <Paper style={{marginLeft: 16, padding: 20, marginTop: 10, marginBottom: 15}}>
                    <AccountingBillHeaderLine selectable={false}/>
                    {line.actual_line_accounting_bills.map((bill, index)=>
                        <AccountingBill key={index} billId={bill.accounting_bill_id} selectable={false} isLeaf={true}/>
                    )}
                </Paper>
            </Collapse>}
        </>
    )
}

export function ActualHeaderLine({isAllocating, selectable}){
    return (
        <FlexRow style={{color: "grey", paddingBottom: 20, paddingLeft: 25, gap: "20px", paddingTop: 15}}>
            {selectable && <FlexCell item style={{minWidth: 30, maxWidth: 30}}/>}
            <FlexCell item minWidth={80}>Item</FlexCell>
            {!isAllocating && <FlexCell item minWidth={250}>Notes</FlexCell>}
            <FlexCell item align="right">Vendor Budget</FlexCell>
            {!isAllocating && <FlexCell item style={{marginLeft: 20, minWidth: 120}} align="right">Vendor Running</FlexCell>}
            {!isAllocating && <FlexCell item align="right">Running Variance</FlexCell>}
            <FlexCell item align="right">Vendor Actual</FlexCell>
            <FlexCell item align="right">Project Actual</FlexCell>

            {isAllocating && <FlexCell item align="right">Portion of Bill</FlexCell>}
        </FlexRow>
    )
}

const ActualLineToggle = ({lineId}) => {
    const dispatch = useDispatch()
    const toAllocateLines = useSelector(selectToAllocateLines) // TODO: optimize the state to be isolated from other lines
    const multiSelect = useSelector(selectCanMultiCheckLines)
    const Toggle = multiSelect ? Checkbox : Radio
    const handleToggle = (event, checked) => {
        checked ? dispatch(checkLine(lineId)) : dispatch(uncheckLine(lineId))
    }
    return (
        <Toggle 
            checked={toAllocateLines.find(l=>l.id==lineId) != null}
            onChange={handleToggle}
            size="small" 
        />
    )
}