import { request } from "@/Api"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Heading } from "@/common/StyledComponents"
import { Alert, Badge, Button, Chip, Grid, Link, Paper, Snackbar, Tab, Tabs } from "@mui/material"
import { ProfileListItem } from "@/profiles_common/CommonComponents"

import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import NumbersRoundedIcon from '@mui/icons-material/NumbersRounded';
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';

import ActualTotals from "./ActualTotals"
import { useDispatch } from "react-redux"
import { selectTab, switchTab, updateBills, updateLines, updateTotals } from "./actualSlice"
import { ActualLinesWithHeader } from "./ActualLines";
import AccountingBills, { AccountingBillsHeader } from "./AccountingBills"
import ActualLinesPopup from "./ActualLinesPopup"
import AccountingBillsPopup from "./AccountingBillsPopup"
import AllocationPopup from "./AllocationPopup"
import { useSelector } from "react-redux"
import { ActualLinePopup } from "./ActualLinePopup"
import AccountingBillDeallocatePopup from "./AccountingBillDeallocatePopup"
import CsvExportButton from "@/common/CsvExportButton"
import AccountingBillRefutePopup from "./AccountingBillRefutePopup"
import { ACCOUNTING_BILL_STATUS_REFUTE_CONFIRMED } from "./constants"
import AccountingBillRefutalConfirmationPopup from "./AccountingBillRefutalConfirmationPopup"
import ProjectFlow from "@/project_common/ProjectFlow"

export default function Actuals(){
    const dispatch = useDispatch()

    const { projectId } = useParams()
    const [ project, setProject ] = useState(null)
    const [ snackBarOpen, setSnackBarOpen ] = useState(false)
    const [ statuses, setStatuses ] = useState([]) // NOTE: creating custom hook, or consider removing status table from backend

    const confirmedBillRefutals = project ? project.accounting_bills.filter(bill => bill.status == ACCOUNTING_BILL_STATUS_REFUTE_CONFIRMED) : []
    const unmatchedBills = project ? project.accounting_bills.filter(bill => bill.accounting_bill_actual_lines.length == 0  && bill.status != ACCOUNTING_BILL_STATUS_REFUTE_CONFIRMED) : []
    const matchedBills = project ? project.accounting_bills.filter(bill => bill.accounting_bill_actual_lines.length > 0) : []

    async function getProject(){
        request.get(`/projects/${projectId}/actuals`)
        .then(response=>{
            const projectData = response.data
            setProject(projectData)
            dispatch(updateTotals(projectData))
            dispatch(updateLines(projectData.actual_lines))
            dispatch(updateBills(projectData.accounting_bills))
        })
    }

    const saveProject = (data) => {        
        request.patch(`projects/${project.id}`, data).then((response)=>{
            const newStatus = response.data.status
            setProject({...project, status: newStatus, status_id: newStatus.id})
        })
    }

    async function getStatueses(){
        request.get("statuses")
        .then((response) => {
            setStatuses(response.data.reduce((obj, status) => (obj[status.name] = status, obj) ,{}))
        })
    }

    useEffect(()=>{
        getProject()
        getStatueses()
    }, [])

    if (!project){
        return null
    }

    const customLinesGroup = {
        isCustomLineGroup: true,
        children: [],
        name: "Custom Lines", 
        line_items: project.actual_lines
        .filter(actual_line=>!actual_line.line_item_id)
        .map(actual_line=>{
            return {actual_line: actual_line}
        })
    }

    const onStatusUpdate = (statusId) => {
        saveProject({status_id: statusId})
    }

    return(
    <div style={{padding: 20, paddingTop: 50}}>
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
            <Grid item>
                <Grid container spacing={2} paddingBottom={2}>
                    <Grid item>
                        <Link href={`/projects/${projectId}`}>
                            <Heading>{project.name}</Heading>
                        </Link>
                    </Grid>
                    <Grid item>
                        <Chip label="Actualization" sx={{background: "#FFEACB", border: "1px solid #CB7F0E", color: "black"}}/>
                    </Grid>
                </Grid>
                <Grid container alignItems={"center"} spacing={2} paddingBottom={4}>
                    <ProfileListItem text={project.client.name} icon={<AccountBoxRoundedIcon />}/>
                    <ProfileListItem text={project.docket} icon={<NumbersRoundedIcon />}/>
                    <ProfileListItem text={project.currency} icon={<AccountBalanceWalletRoundedIcon />}/>
                    <ProfileListItem text={project.legal_entity.name} icon={<BusinessRoundedIcon />}/>
                    <ProfileListItem text={project.owner.name} icon={<PersonRoundedIcon />}/>
                    <ProfileListItem text={project.status.name} icon={<AccessTimeRoundedIcon />} />
                </Grid>
            </Grid>
            <Grid item>
                <ProjectFlow 
                    onStatusUpdate={onStatusUpdate}
                    statuses={statuses}
                    project={project}
                    mode={"actuals"}
                />
            </Grid>
        </Grid>

        <div style={{marginBottom: 50}}>
            <ActualTotals />
        </div>

        <TabSwitcher unmatchedBillsLength={unmatchedBills.length}/>

        <TabWrapper tabId={0}>
            <div style={{paddingBottom: 20}}>
                <AccountingBillsHeader />
                <Paper sx={{padding: 3, marginBottom: 10, boxShadow: "none"}}>
                    <Grid container>
                        <Grid item><h4 style={{margin: 0, paddingBottom: 20, paddingRight: 20}}>Unmatched bills</h4></Grid>
                        <Grid item><Badge badgeContent={unmatchedBills.length} color="primary"/></Grid>
                    </Grid>
                    <AccountingBills bills={unmatchedBills} selectable={true}/>
                </Paper>
                <div style={{paddingBottom: 80}}>
                    <Grid item><h4 style={{margin: 0, paddingBottom: 20, paddingRight: 20}}>Matched bills</h4></Grid>
                    <AccountingBills bills={matchedBills} selectable={false}/>
                </div>
                <div>
                    <Grid item><h4 style={{margin: 0, paddingBottom: 20, paddingRight: 20}}>Confirmed bill refutals</h4></Grid>
                    <AccountingBills bills={confirmedBillRefutals} selectable={false}/>
                </div>
            </div>
            <CsvExportButton model="accounting-bill" filters={{project_id: projectId}} />
        </TabWrapper>

        <TabWrapper tabId={1}>
            <div style={{paddingBottom: 10}}>
                <ActualLinesWithHeader
                    bids={project.signed_bids}
                    customLinesGroup={customLinesGroup}
                    selectable={false}
                />
            </div>
            <CsvExportButton model="actual-line" filters={{project_id: projectId}}/>
        </TabWrapper>

        <ActualLinesPopup 
            bids={project.signed_bids}
            customLinesGroup={customLinesGroup}
        />
        <AccountingBillsPopup 
            bills={unmatchedBills}
        />
        <AllocationPopup 
            getProject={getProject}
            openSnackBar={()=>{setSnackBarOpen(true)}}
        />
        <ActualLinePopup 
            signedBids={project.signed_bids}
            getProject={getProject}
        />
        <AccountingBillDeallocatePopup 
            getProject={getProject}
        />
        <AccountingBillRefutePopup 
            getProject={getProject}
        />
        <AccountingBillRefutalConfirmationPopup 
            getProject={getProject}
        />

        <Snackbar
            open={snackBarOpen}
            autoHideDuration={2000}
            onClose={() => {setSnackBarOpen(false)}}
        >
            <Alert severity="success">Bill allocation success!</Alert>
        </Snackbar>
    </div>
    )
}

function TabWrapper({children, tabId}){
    const tab = useSelector(selectTab)
    return (
        <div hidden={tabId != tab} style={{position: "relative"}}>
            {children}
        </div>
    )
}

function TabSwitcher(){
    const dispatch = useDispatch()
    const tab = useSelector(selectTab)
    return (
        <Tabs value={tab} onChange={(e, tab)=>{dispatch(switchTab(tab))}} sx={{borderBottom: 1, color: 'grey', marginBottom: 3, minHeight: 0, padding: 0}}>
            <Tab label="Bills" sx={{fontSize: 12, minWidth: 20, minHeight: 0, paddingLeft: 2, paddingRight: 2}}/>
            <Tab label="Running Budget" sx={{fontSize: 12, minWidth: 20, minHeight: 0, padding: 1}}/>
        </Tabs>
    )
}