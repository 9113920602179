import { useState, useMemo, useEffect } from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { useDispatch } from "react-redux"

import { ThemeProvider } from '@mui/material/styles'
import Box from "@mui/material/Box"

import BaseContainer from "@/common/BaseContainer"
import ProtectedContainer from "@/auth/ProtectedContainer"
import SignUp from "@/auth/SignUp"
import SetUp from "@/auth/SetUp"
import Login from "@/auth/Login"
import Account from "@/account/Account"
import ForgotPassword from "@/auth/ForgotPassword"
import ResetPassword from "@/auth/ResetPassword"
import Logout from "@/auth/Logout"
import Dashboard from "@/dashboard/Dashboard"
import Projects from "@/projects/Projects"
import Project from "@/project/Project"
import Invoices from "@/invoices/Invoices"
import Persons from "@/persons/Persons"
import Bills from "@/bills/Bills"
import Clients from "@/clients/Clients"
import Client from "./clients/Client"
import Admin from "@/admin/Admin"
import Bids from "@/bids/Bids"
import Bid from "@/bid/Bid"
import Actuals from "@/actuals/Actuals"
import TemplateBids from "@/bids/TemplateBids"
import Reports from "./report/Reports"
import _404 from "@/auth/404"
import Confirmation from "@/public/Confirmation"

import { GlobalStyle, baseTheme, darkTheme } from "@/themes"
import ProtectedComponents from "@/auth/ProtectedComponents"
import ScrollToTop from "@/common/ScrollToTop"
import PublicNav from "@/nav/PublicNav"
import TopNav from "@/nav/TopNav"
import SideNav from "@/nav/SideNav"
import MobileNav from "@/nav/MobileNav"
import Popups from "@/nav/Popups"
import Error from "@/nav/Error"
import Footer from "@/nav/Footer"

import Api from "@/Api"
import { PersonProfile } from "@/person_profile/PersonProfile"
import { ProjectProfile } from "@/project_profiles/ProjectProfile"
import { OrgProfile } from "@/org_profile/OrgProfile"
import PersonProfiles from "@/person_profiles/PersonProfiles"

import { setToken } from "@/auth/authSlice"
import SuperAdmin from "./admin/SuperAdmin"
import JoinOrganization from "./auth/OrganizationJoiner"
import PublicTerm from "./public/PublicTerm"

export default function App() {
    const [ loaded, setLoaded ] = useState(false)
    const [themeMode, setThemeMode] = useState(localStorage.getItem("themeMode") || "light")
    const theme = useMemo(() => themeMode == "dark" ? darkTheme : baseTheme, [themeMode])
    const dispatch = useDispatch()

    const toggleTheme = () => {
        const newThemeMode = themeMode === 'light' ? 'dark' : 'light'
        setThemeMode(newThemeMode)
        localStorage.setItem("themeMode", newThemeMode)
    }

    useEffect(() => {
        const token = localStorage.getItem("jwtToken")
        if (token) {
            dispatch(setToken(token))
        }
        setLoaded(true)
    }, [])

    if (!loaded) {
        return null
    }
    
    return (
        <ThemeProvider theme={theme}>
            <GlobalStyle theme={themeMode} />
            <BrowserRouter>
                <PublicNav />
                <ScrollToTop />
                <Api />
                <Error />

                <Box sx={{ display: 'flex', position: "relative" }}>
                    <ProtectedComponents>
                        <TopNav toggleTheme={toggleTheme}/>
                        <SideNav />
                        <MobileNav toggleTheme={toggleTheme}/>
                        <Popups />
                    </ProtectedComponents>
                    <BaseContainer>
                        <Routes>
                            <Route path="/join-organization/:inviteToken" element={ <JoinOrganization />} />
                            <Route path="/sign-up/:inviteToken?" element={ <SignUp /> }/>
                            <Route path="/login/:inviteToken?" element={ <Login /> }/>
                            <Route path="/forgot-password" element={ <ForgotPassword /> }/>
                            <Route path="/reset-password/:token" element={ <ResetPassword /> }/>
                            <Route path="/logout" element={ <Logout /> }/>

                            <Route path="/set-up" element={<ProtectedContainer container={<SetUp />} />}/>
                            <Route path="/account" element={<ProtectedContainer container={<Account />} />} />
                            
                            <Route path="/talent" element={<PersonProfiles/>} />
                            <Route path="/profile/:profileHandle?" element={<PersonProfile/>} />
                            <Route path="/company-profile/:profileHandle?" element={<OrgProfile/>} />
                            <Route path="/project-profile/:profileHandle?" element={<ProjectProfile/>} />

                            <Route path="/dashboard?" element={<ProtectedContainer container={<Dashboard/>} />} />
                            <Route path="/projects/:projectId" element={<ProtectedContainer container={<Project/>} permission="get_projects" />} />
                            <Route path="/projects" element={<ProtectedContainer container={<Projects/>} permission="get_projects" />} />
                            <Route path="/bids/:bidId" element={<ProtectedContainer container={<Bid/>} permission="get_bids" />} />

                            <Route path="/projects/:projectId/actuals" element={<ProtectedContainer container={<Actuals/>} permission="get_bids" />} />

                            <Route path="/bids" element={<ProtectedContainer container={<Bids/>} permission="post_bids" />} />
                            <Route path="/invoices" element={<ProtectedContainer container={<Invoices/>} permission="get_invoices" />} />
                            <Route path="/bills" element={<ProtectedContainer container={<Bills/>} permission="get_bills" />} />
                            <Route path="/team" element={<ProtectedContainer container={<Persons/>} permission="edit_persons" />} />
                            <Route path="/clients/:clientId" element={<ProtectedContainer container={<Client/>} permission="get_clients" />} />
                            <Route path="/clients" element={<ProtectedContainer container={<Clients/>} permission="edit_clients" />} />
                            <Route path="/template-bids" element={<ProtectedContainer container={<TemplateBids/>} permission="post_bids" />}/>
                            <Route path="/reports" element={<ProtectedContainer container={<Reports/>} permission="get_projects" />} />
                            <Route path="/admin" element={ <ProtectedContainer container={<Admin/>} permission="view_admin_panel" /> } />
                            <Route path="/super-admin" element={ <ProtectedContainer container={<SuperAdmin/>} requireSuperAdmin /> } />
                            
                            <Route path="/terms-of-use" element={ <PublicTerm name={"Terms of Use"} />} />
                            <Route path="/privacy-policy" element={ <PublicTerm name={"Privacy Policy"} />} />
                            <Route path="/thank-you-for-signing" element={ <Confirmation description={"Thank you for providing your signature. A copy of the signed document has been sent to your inbox for your records. Should you have any questions, please contact your producer directly."} />} />
                            
                            <Route path="*" element={<ProtectedContainer container={<_404/>} /> } />
                        </Routes>
                        
                    </BaseContainer>
                    
                    <Footer />
                </Box>
            </BrowserRouter>
        </ThemeProvider>
    )
}
